import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const souffrance = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Souffrance" />
    <h3 className='underline-title'>Souffrance</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        L'inégalable souffrance<br />
        Souffrance-moteur<br />
        élément de mouvement:<br />
        <span className="par-starting"></span>- muse des poètes<br />
        <span className="par-starting"></span>- tonic des fatigués <br />
        <span className="par-starting"></span>- réveil des sommeillants<br />
      </p>
      <p>
        Souffrance-la-multidimensionnelle:<br />
        <span className="par-starting"></span>- profondeur du lac Baïkal<br />
        <span className="par-starting"></span><span className="par-starting"></span>insondable<br />
        <span className="par-starting"></span>- hauteur d'Everest<br />
        <span className="par-starting"></span><span className="par-starting"></span>pic des pics<br />
        <span className="par-starting"></span>- longueur du Zaïre<br />
        <span className="par-starting"></span><span className="par-starting"></span>cousin d'Amazone<br />
      </p>
      <p>
        Je me rappelle<br />
        Le jour où passa la souffrance<br />
        J'ai vu:<br />
        <span className="par-starting"></span>- la Sibérie embrasée<br />
        <span className="par-starting"></span>- et l'Équateur surgelé <br />
        <span className="par-starting"></span>- le Blanc Noirci <br />
        <span className="par-starting"></span>- et le Nègre blanchi <br />
        C'était la souffrance métamorphosante<br />
      </p>
      <p>
        Souffrance-la-thaumaturge:<br />
        <span className="par-starting"></span>- elle opère tout<br />
        <span className="par-starting"></span>- elle transforme des cailloux en pluie <br />
        <span className="par-starting"></span>- elle change des humains en mer <br />
      </p>
      <p>
        Ce jour-là jour des miracles:<br />
        <span className="par-starting"></span>- pluie écarlate en Asie <br />
        <span className="par-starting"></span>- pain de pierre en Angola <br />
        <span className="par-starting"></span>- sauterelles d'acier au Viet-nam <br />
        <span className="par-starting"></span>- étoile noire à Kinshasa <br />
      </p>
      <p>
        Bonjour la souffrance!<br />
        <span className="par-starting"></span>grace à toi <br />
        <span className="par-starting"></span>la boue est habitable <br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default souffrance
